import React, { useRef, useEffect, useState } from 'react'
import { IoIosSend } from 'react-icons/io';
import Button from '../../Pages/Static/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PatientForm from '../Patients/PatientForm';
import axios from 'axios';
import { VscLoading } from "react-icons/vsc";
import { toast } from 'react-hot-toast';
import HamBurgor from '../../Pages/Static/HamBurgor';
import { MdArrowDownward } from 'react-icons/md';
import { myContext } from '../../../myContext';

function ChatWindow({ themeMode, token, selectedPatient, messages, groupedMessages, inputMessage, setInputMessage, handleKeyDown, handleSendMessage, me }) {

    const { setSelectedPatient } = React.useContext(myContext);
    const navigate = useNavigate();
    const chatRef = useRef(null);
    const [hamOpened, setHamOpened] = useState(false);
    const [editPatient, setEditPatient] = useState({ show: false, patientId: selectedPatient.id });
    const [editFormData, setEditFormData] = useState({
        name: '',
        email: '',
        phone: '',
        profilePicture: null,
    });
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const imageRef = useRef(null);
    const [confirmDelete, setConfirmDelete] = useState({ show: false, patientId: selectedPatient.id });
    const menuRef = useRef(null);
    const prevMessagesLength = useRef(messages.length);
    const textareaRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setHamOpened(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []);

    const handleDelete = async () => {
        setLoading(true);
        try {
            const res = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/patient/deletePatient/${confirmDelete.patient}`, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (res.status === 200) {
                setConfirmDelete({
                    patient: null,
                    show: false
                });
                toast.success('Patient deleted successfully');
                setLoading(false);
                window.location.reload();
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleFileChange = (e) => {
        setEditFormData({
            ...editFormData,
            [e.target.name]: e.target.files[0]
        })
        setSelectedImage(e.target.files[0])
    }

    const handleImageDeselct = () => {
        setSelectedImage(null);
        imageRef.current.value = null;
    }

    const handleEdit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('name', editFormData.name);
        formData.append('email', editFormData.email);
        formData.append('phone', editFormData.phone);
        formData.append('profilePicture', editFormData.profilePicture);
        try {
            const res = await axios.put(`${process.env.REACT_APP_ENDPOINT}/patient/updatePatient/${selectedPatient.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            setLoading(false);
            setEditPatient({ show: false, patientId: '' });
            setEditFormData({
                name: '',
                email: '',
                phone: '',
                profilePicture: ''
            });
            window.location.reload();
            toast.success('Patient updated successfully');
        } catch (error) {
            console.error('Error editing patient:', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setEditFormData({
                name: selectedPatient.name,
                email: selectedPatient.email,
                phone: selectedPatient.phone,
                profilePicture: selectedPatient.profilePicture || null
            });
        };

        fetchData();
    }, [selectedPatient]);

    useEffect(() => {
        const scrollToBottom = () => {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        };

        if (messages.length > prevMessagesLength.current) {
            setTimeout(scrollToBottom, 50);
            prevMessagesLength.current = messages.length;
        }
    }, [messages, prevMessagesLength]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [inputMessage]);

    return (
        <div className={`relative flex flex-col gap-4 overflow-x-hidden ml-5 p-7 w-full rounded-2xl duration-300 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-100'}`}>
            {selectedPatient.id !== '' ? (
                <>
                    {
                        loading === true ? <div className='flex justify-center items-center w-full h-full pb-10'><VscLoading className='text-[4rem] animate-spin text-blue-700' /></div> :
                            <>
                                <div className={`w-full flex justify-between gap-5 items-center px-3 pr-8 py-2 duration-300 
                                     absolute top-0 left-0 z-10 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-100'}`}>
                                    <div className='flex items-center gap-5 duration-300 cursor-pointer border-transparent border px-2 pr-4 py-1 rounded-full hover:border-blue-700'
                                        onClick={() => navigate(`/dashboard/patients/${selectedPatient.id}`)} >
                                        <img src={selectedPatient.profilePicture ? selectedPatient.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'}
                                            alt='' className='w-[60px] h-[60px] rounded-full object-cover' />
                                        <div className='flex flex-col '>
                                            <h1 className='text-2xl font-semibold transition-none'>{selectedPatient.name}</h1>
                                            <p className='text-sm opacity-50'>Klicken Sie hier, um das Profil anzuzeigen</p>
                                        </div>
                                    </div>
                                    <HamBurgor hamOpened={hamOpened} setHamOpened={setHamOpened} menuRef={menuRef}
                                        themeMode={themeMode} selectedPatient={selectedPatient} setEditPatient={setEditPatient}
                                        setConfirmDelete={setConfirmDelete}
                                    />
                                </div>
                                <div className='flex flex-col h-full overflow-y-auto pt-[3.6rem] mb-[5.3rem]' ref={chatRef}>
                                    {Object.keys(groupedMessages).length === 0 ? (
                                        <div className='flex w-full h-full justify-center items-center '>
                                            <p>Dies ist der Beginn Ihres Gesprächs mit {selectedPatient.name}.</p>
                                        </div>
                                    ) : (
                                        Object.keys(groupedMessages).map((date) => (
                                            <div key={date}>
                                                <div className='flex w-full justify-center items-center'>
                                                    <p className='bg-[rgb(100,100,100)] text-white text-center w-[120px] mt-2 rounded-full p-2 px-4'>{moment(date).format('DD-MM-YYYY')}</p>
                                                </div>
                                                {groupedMessages[date].map((message) => (
                                                    <div key={message._id} className={`flex w-full p-3 text-xl justify-${message.sender === me ? 'end' : 'start'}`}>
                                                        <div className={`mx-3 px-3 py-2 rounded-lg relative shadow-md max-w-[500px] ${message.sender === me ? themeMode !== 'dark' ?
                                                            'bg-blue-900 text-white text-right' : 'bg-blue-900 text-white text-right'
                                                            : themeMode !== 'dark' ? 'bg-gray-300 text-black text-left' : 'bg-gray-700 text-white text-left'}`}>
                                                            <div className={`absolute ${message.sender === me ? 'right-[-0.54rem] rounded-br-full rounded-tr-sm' :
                                                                'left-[-0.56rem] rounded-bl-full rounded-tl-sm'} top-0 w-[15px] 
                                                                 h-[15px] bg-${message.sender === me ? 'blue-900' : themeMode !== 'dark' ? 'gray-300' : 'gray-700'} `}></div>
                                                            <div className={`whitespace-pre-wrap break-words text-left`}>
                                                                {message.text}
                                                            </div>
                                                            <p className={`flex justify-end items-center ${themeMode === 'dark' ? 'text-gray-400' : 'text-gray-400'} `}>
                                                                {moment(message.date).format('hh:mm A')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    )}
                                </div>

                                <div className={`flex justify-start absolute  items-center duration-300 w-full py-3 pr-20 bottom-5
                                             ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-100'}`}>
                                    <textarea
                                        ref={textareaRef}
                                        placeholder='Nachricht eingeben...'
                                        value={inputMessage}
                                        onChange={(e) => setInputMessage(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        className={`flex-1 text-xl border-2 bg-transparent overflow-y-auto rounded-2xl px-5 py-2 
                                                ${themeMode === 'dark' ? 'border-gray-600' : 'border-gray-300'} focus:outline-none resize-none`}
                                        style={{ maxHeight: '500px' }}
                                    />
                                    <div className='text-3xl ml-5'>
                                        <Button icon={<IoIosSend />} onClick={handleSendMessage} />
                                    </div>
                                </div>
                            </>
                    }
                </>
            ) : (
                <div className='flex w-full h-full justify-center items-center '>
                    <p className='animate-bounce mr-3'>{"<"}</p>
                    <p>Wählen Sie einen Patienten aus, um das Chatten zu starten.</p>
                </div>
            )
            }
            {
                editPatient.show === true && <PatientForm token={token} setModal={setEditPatient}
                    text='Patient bearbeiten' handleFileChange={handleFileChange} loading={loading}
                    formData={editFormData} setFormData={setEditFormData} handleEdit={handleEdit}
                    patient={editPatient.patientId} imageRef={imageRef} handleImageDeselct={handleImageDeselct}
                    selectedImage={selectedImage}
                />
            }
            {
                confirmDelete.show === true && (
                    <div className='absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center'>
                        <div className=' w-[500px] h-[220px] backdrop-blur-[15px] rounded-xl shadow-2xl'
                            style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.5)'}` }}
                        >
                            <div className='flex flex-col justify-between p-10'>
                                <div className='flex flex-col'>
                                    <p className='text-2xl'>Patient löschen?</p>
                                    <p>Sind Sie sicher, dass Sie diesen Patienten löschen möchten?</p>
                                </div>
                                <div className='flex justify-end w-full gap-5 mt-10'>
                                    <Button text='Abbrechen' onClick={() => setConfirmDelete(!confirmDelete)} />
                                    {
                                        loading === true ?
                                            <Button text='Warten...' icon={<VscLoading />} spinner={true} disabled={true} />
                                            :
                                            <Button text='Löschen' onClick={handleDelete} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}

export default ChatWindow