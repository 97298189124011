import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";
import Button from "../../Pages/Static/Button";
import { myContext } from "../../../myContext";
import moment from "moment";

export default function EditFollowUpForm({
  token,
  followUp,
  setFollowUp,
  loading,
  setLoading,
  patient,
  getPatientInfos,
}) {
  const { themeMode } = React.useContext(myContext);
  const [formData, setFormData] = useState({
    patient: followUp.patient || "",
    dateTime: followUp.dateTime || "",
    notes: followUp.notes || "",
    reason: followUp.reason || "",
    mainVisitId: followUp.mainVisit || "",
    location: followUp.location || "",
  });
  const [patientInfo, setPatientInfo] = useState({});

  const getPatientInfo = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/patient/getPatient/${patient}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setPatientInfo(res.data.patient);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Etwas ist schiefgelaufen!");
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []);
  // console.log(formData.dateTime);
  const handleEditFollowUp = async () => {
    if (formData.dateTime === "")
      return toast.error("Bitte wählen Sie ein Datum und eine Uhrzeit aus");

    setLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_ENDPOINT}/visit/editFollowUp/${followUp._id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          setFollowUp(null);
          getPatientInfos();
          toast.success("Folgebesuch erfolgreich bearbeitet");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Etwas ist schiefgelaufen!");
      });
  };

  return (
    <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
      <div
        className=" w-auto h-auto backdrop-blur-[10px] rounded-xl shadow-2xl "
        style={{
          backgroundColor: `${
            themeMode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(255,255,255,0.5)"
          }`,
        }}
      >
        <div className="flex flex-col p-10 text-lg">
          <div className="flex justify-start items-center">
            <p className="text-2xl mr-5 ">Nachfolgebesuch für:</p>
            <div className="flex mt-3 justify-start items-center bg-blue-800 px-3 py-1 rounded-full w-[210px] h-[60px]">
              {loading === true ? (
                <VscLoading className="animate-spin text-2xl text-white" />
              ) : (
                <img
                  src={
                    patientInfo.profilePicture
                      ? patientInfo.profilePicture
                      : "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
                  }
                  alt="profile"
                  className="w-[40px] h-[40px] rounded-full"
                />
              )}
              <div className="flex flex-col ml-4 text-white">
                {loading === true ? (
                  <VscLoading className="animate-spin text-center ml-10 text-2xl text-white" />
                ) : (
                  <>
                    <p>{patientInfo.name}</p>
                    <p>{patientInfo.phone}</p>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-10">
            <div>
              <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-[400px]">
                  <label className="text-2xl">
                    <span className="border-b pb-[0.1rem]">
                      {followUp.reason}
                    </span>
                  </label>
                </div>
              </div>

              <div className="flex flex-col mt-5">
                <label className="">Datum und Uhrzeit</label>
                <input
                  type="datetime-local"
                  name="dateTime"
                  placeholder="Datum und Uhrzeit"
                  className="mt-2 p-2 px-4 rounded-xl bg-transparent border"
                  value={moment
                    .utc(formData.dateTime)
                    .format("yyyy-MM-DDTHH:mm:ss")}
                  onChange={(e) =>
                    setFormData({ ...formData, dateTime: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-5">
                <label className="">Notizen</label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Notizen"
                  className="mt-2 p-2 px-4 h-[200px] resize-none rounded-xl bg-transparent border"
                  value={formData.notes}
                  onChange={(e) =>
                    setFormData({ ...formData, notes: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-5">
                <label className="">Ort</label>
                <select
                  name='location'
                  className='mt-2 p-2 px-4 rounded-xl bg-transparent border'
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                >
                  <option className='text-black' value='Aurich'>Aurich</option>
                  <option className='text-black' value='Wittmund'>Wittmund</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full gap-5 px-10 pb-10 duration-300">
          <Button text="Abbrechen" onClick={() => setFollowUp(null)} />
          {loading === true ? (
            <Button
              text="Warten..."
              icon={<VscLoading />}
              spinner={true}
              disabled={true}
            />
          ) : (
            <>
              <Button text="Senden" onClick={handleEditFollowUp} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
