import React from 'react';
import Button from '../../../Components/Pages/Static/Button';
import { VscLoading } from 'react-icons/vsc';

function PasswordChange({
  themeMode,
  form,
  setForm,
  onClick,
  loading
}) {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div className='flex '>
      <div className={`flex items-center duration-300 justify-center relative rounded-2xl mt-10 w-[300px] ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-[rgb(230,230,230)]'}`}>
        <div className={`flex flex-col mb-10 group relative cursor-pointer rounded-full`}>
          {loading === true ? (
            <VscLoading className='text-4xl animate-spin text-blue-700' />
          ) : (
            <>
              <img
                src='https://www.w3schools.com/howto/img_avatar.png'
                alt='avatar'
                className='w-40 h-40 rounded-full border-2 border-blue-500 p-2 border-dashed group-hover:opacity-60 transition-opacity duration-300'
              />
              <div className='flex opacity-0 justify-center items-center absolute group-hover:opacity-100 w-full h-full'>
                <p className='text-lg font-bold'>Avatar ändern</p>
              </div>
            </>
          )}
        </div>
        <p className='text-sm text-center opacity-70 absolute bottom-0 mb-10'>Erlaubt *.jpeg, *.jpg, *.png, *.webp </p>
      </div>

      <div className={`flex flex-col gap-5 ml-10 mt-10 p-10 duration-300 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-[rgb(230,230,230)]'}`}>
        <div className='flex flex-col gap-1 relative'>
          <p className={`text-base duration-300 text-gray-400 font-bold absolute top-[-1rem] left-2 p-0.5 px-1 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-[rgb(230,230,230)]'}`}>Aktuelles Passwort</p>
          <input
            type='password'
            className={`w-[300px] h-12 rounded-lg px-5 transition-all duration-300 ease-in-out ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] border border-gray-300' : 'bg-[rgb(230,230,230)] border border-gray-500'}`}
            value={loading === true ? 'Wird geladen...' : form.currentPass}
            disabled={loading === true ? true : false}
            onChange={(e) => setForm({ ...form, currentPass: e.target.value })}
          />
        </div>
        <div className='flex flex-col gap-1 relative mt-5'>
          <p className={`text-base duration-300 text-gray-400 font-bold absolute top-[-1rem] left-2 p-0.5 px-1 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-[rgb(230,230,230)]'}`}>Neues Passwort</p>
          <input
            type='password'
            className={`w-[300px] h-12 rounded-lg px-5 transition-all duration-300 ease-in-out ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] border border-gray-300' : 'bg-[rgb(230,230,230)] border border-gray-500'}`}
            value={loading === true ? 'Wird geladen...' : form.newPass}
            disabled={loading === true ? true : false}
            onChange={(e) => setForm({ ...form, newPass: e.target.value })}
          />
        </div>
        <div className='flex flex-col gap-1 relative mt-5'>
          <p className={`text-base duration-300 text-gray-400 font-bold absolute top-[-1rem] left-2 p-0.5 px-1 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-[rgb(230,230,230)]'}`}>Passwort bestätigen</p>
          <input
            type='password'
            className={`w-[300px] h-12 rounded-lg px-5 transition-all duration-300 ease-in-out ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] border border-gray-300' : 'bg-[rgb(230,230,230)] border border-gray-500'}`}
          />
        </div>
        <div className='flex justify-end mt-5'>
          {loading === true ? (
            <Button text='Warten...' icon={<VscLoading />} spinner={true} disabled={true} />
          ) : (
            <Button text='Änderungen speichern' onClick={onClick} typeButton="submit" onKeyDown={onKeyPress} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordChange;