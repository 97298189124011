import React, { useEffect } from 'react';
import Button from '../../Pages/Static/Button';
import { VscLoading } from "react-icons/vsc";
import { myContext } from '../../../myContext';

function PatientForm({
    setModal,
    formData,
    setFormData,
    loading,
    handleReasonChange,
    text,
    handleFileChange,
    onClick,
    selectedImage,
    handleOtherReasonChange,
    imageRef,
    handleImageDeselct,
    handleEdit
}) {
    const { themeMode } = React.useContext(myContext);

    useEffect(() => {
        console.log(formData)
    }, [formData]);

    return (
        <div className='absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center'>
            <div className=' w-auto h-auto backdrop-blur-[15px] rounded-xl shadow-2xl'
                style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.5)'}` }}
            >
                <div className='flex flex-col p-10 text-lg'>
                    <p className='text-2xl'>{text}</p>

                    <div className='flex gap-5 mt-4'>
                        <div className='flex flex-col'>
                            <label className=''>Name</label>
                            <input type='text' name='name' placeholder='Name'
                                className='mt-2 p-2 px-4 rounded-xl bg-transparent border' value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        </div>
                        {
                            handleEdit ? null :
                                <div className='flex flex-col justify-center'>
                                    <label className=''>E-Mail</label>
                                    <input type='text' name='email' placeholder='E-Mail'
                                        className='mt-2 p-2 px-4 rounded-xl bg-transparent border' value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                </div>
                        }
                    </div>

                    <div className='flex gap-5 mt-4'>
                        <div className='flex flex-col'>
                            <label className=' mt-2'>Telefonnummer</label>
                            <input type='text' name='phone' placeholder='Telefon'
                                className='mt-2 p-2 px-4 rounded-xl bg-transparent border' value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                        </div>
                        {
                            handleEdit ? null :
                                <div className='flex flex-col'>
                                    <label className=' mt-2'>Grund des Besuchs?</label>
                                    <select
                                        name='reason'
                                        className='mt-2 p-2 px-4 rounded-xl bg-transparent border'
                                        value={formData.reason}
                                        onChange={handleReasonChange}
                                    >
                                        <option className='text-black' value=''>Grund auswählen</option>
                                        <option className='text-black' value='Zahnfüllung'>Zahnfüllung</option>
                                        <option className='text-black' value='Prothesen'>Prothesen</option>
                                        <option className='text-black' value='Wurzelkanal'>Wurzelkanalbehandlung</option>
                                        <option className='text-black' value='Zahnkrone'>Zahnkrone</option>
                                        <option className='text-black' value='Zahnimplantat'>Zahnimplantat</option>
                                        <option className='text-black' value='Anderer Grund'>Anderer Grund</option>
                                    </select>
                                </div>
                        }
                    </div>
                    {formData.reason === 'Anderer Grund' && (
                        <div className='mt-5 flex flex-col'>
                            <label>Anderen Grund angeben</label>
                            <input
                                type='text'
                                name='otherReason'
                                className='p-2 px-4 mt-2 rounded-xl bg-transparent border'
                                value={formData.otherReason}
                                onChange={handleOtherReasonChange}
                            />
                        </div>
                    )}
                     <div className='flex flex-col'>
                            <label className=' mt-2'>Geburtsdatum</label>
                            <input type='date' name='dob' placeholder='Geburtsdatum'
                                className='mt-2 p-2 px-4 rounded-xl bg-transparent border' value={formData.dob} onChange={(e) => setFormData({ ...formData, dob: e.target.value })} />
                        </div>
                    <div className='flex flex-col mt-5 items-center'>
                        {
                            selectedImage !== null ?
                                <div className='relative'>
                                    <p className='absolute right-[-2rem] border border-gray-400 rounded-full px-2 text-2xl hover:bg-blue-800 hover:scale-105 duration-300 cursor-pointer'
                                        onClick={handleImageDeselct}
                                    >X</p>
                                    <div className='flex justify-between items-center gap-10'>
                                        <p className='text-center'>Ausgewähltes Bild</p>
                                        <img src={selectedImage && URL.createObjectURL(selectedImage)} alt='Profilbild'
                                            ref={imageRef}
                                            className='w-[100px] h-[100px] rounded-full object-cover' />
                                    </div>
                                </div>
                                :
                                <div className='mt-5 w-[250px] flex justify-center'>
                                    <label className='px-3 py-2 text-center rounded-xl cursor-pointer hover:rounded-3xl border-gray-400 duration-300 border-dashed border'
                                        htmlFor='profilePicture'
                                    >{handleEdit ? 'Profilbild bearbeiten' : 'Neues Profilbild auswählen'}</label>
                                    <input type='file' name='profilePicture' id='profilePicture' className='hidden' onChange={handleFileChange} />
                                </div>
                        }

                    </div>
                </div>
                <div className='flex justify-end w-full gap-5 px-10 pb-10 duration-300'>
                    <Button text='Abbrechen' onClick={() => setModal(false)} />
                    {loading === true ? (
                        <Button text='Warten...' icon={<VscLoading />} spinner={true} disabled={true} />
                    ) : (
                        <>
                            <Button text={handleEdit ? 'Aktualisieren' : 'Patient hinzufügen'} onClick={onClick || handleEdit} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PatientForm;
