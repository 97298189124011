import React, { useState, useEffect } from 'react';
import { myContext } from '../../../myContext';
import axios from 'axios';
import io from 'socket.io-client';
import ChatWindow from './ChatWindow';
import { VscLoading } from 'react-icons/vsc';

export default function Chat({ token }) {
  const { themeMode, setSelectedPatient, selectedPatient, setNotificationReceived } = React.useContext(myContext);
  const [inputMessage, setInputMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [patients, setPatients] = useState([]);
  const [me, setMe] = useState(null);
  const [socket, setSocket] = useState(null);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [search, setSearch] = useState('');
  const [unreadMessages, setUnreadMessages] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initializeSocket = () => {
      const newSocket = io(process.env.REACT_APP_SOCKETENDPOINT, {
        withCredentials: true,
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      newSocket.on('connect', () => {
        // console.log('Socket verbunden');
        newSocket.emit('join', me);
      });

      newSocket.on('disconnect', () => {
        // console.log('Socket getrennt');
      });

      setSocket(newSocket);
      return () => {
        newSocket.off('receiveMessage');
        newSocket.disconnect();
      };
    };

    initializeSocket();
  }, [me, token]);

  useEffect(() => {
    if (socket) {
      socket.on('receiveMessage', (newMessage) => {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setUnreadMessages((prevUnread) => ({
          ...prevUnread,
          [newMessage.sender]: (prevUnread[newMessage.sender] || 0) + 1,
        }));
        setNotificationReceived(true);
        getMeAndPatients();
      });

    } else {
      console.error('Socket connection not established.');
    }

    return () => {
      if (socket) {
        socket.off('receiveMessage');
      }
    };
  }, [socket]);

  const getMeAndPatients = async () => {
    try {
      const meResponse = await axios.get(`${process.env.REACT_APP_ENDPOINT}/auth/me`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (meResponse.status === 200) {
        setMe(meResponse.data._id);
        const counts = meResponse.data.unreadMessages.reduce((acc, curr) => {
          acc[curr.patientId] = curr.count;
          return acc;
        }, {});
        setUnreadMessages(counts);
      }

      const patientsResponse = await axios.get(`${process.env.REACT_APP_ENDPOINT}/patient/getPatientsWithMessages`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (patientsResponse.status === 200) {
        setPatients(patientsResponse.data.patients);
      }
    } catch (error) {
      console.error('Error retrieving user and patient data:', error);
    }
  };

  useEffect(() => {
    getMeAndPatients();
    fetchMessages();
  }, [token,selectedPatient]);

  useEffect(() => {
    const grouped = {};
    messages.forEach((message) => {
      const date = new Date(message.date).toISOString().split('T')[0];
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(message);
    });
    setGroupedMessages(grouped);
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/message/getMessages`,
        {
          params: {
            receiverId: selectedPatient.id,
            senderId: me,
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages(response.data);

      const counts = response.data.reduce((acc, curr) => {
        acc[curr.sender] = curr.count;
        return acc;
      }, {});

      setUnreadMessages((prevUnread) => ({
        ...prevUnread,
        ...counts,
      }));
    } catch (error) {
      console.error('Error retrieving messages:', error);
    }
  };

  const handlePatientClick = (id, name, profilePicture, email, phone) => {
    if (socket) {
      socket.emit('leave', selectedPatient.id);
    }

    setSelectedPatient({ id: id, name, profilePicture, email, phone });
    setNotificationReceived(false)
    socket.emit('readMessages', { senderId: id, receiverId: me });
    if (socket) {
      socket.emit('join', id);
    }
  };

  const handleSendMessage = async () => {
    try {
      if (inputMessage.trim() === '' || !selectedPatient) return;

      const receiverId = selectedPatient.id;

      const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
      const time = new Date().toLocaleTimeString('en-US', timeOptions);

      if (socket) {
        socket.emit('sendMessage', { senderId: me, receiverId, text: inputMessage, time });
      } 

      getMeAndPatients();
      setInputMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const filteredPatients = patients.filter((patient) => {
    return patient.name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className={`w-full h-[100vh] pl-[18rem] flex pr-10 pt-[7rem] pb-8 duration-300 ${themeMode === 'dark' ? 'bg-[rgb(40,40,40)]' : 'bg-slate-200'} rounded-xl`}>
      {
        loading ? <div className={` flex w-full h-full justify-center items-center`}>
          <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
        </div>
          :
          <>
            <div className={`flex flex-col gap-4 w-[700px] rounded-2xl duration-300 overflow-y-auto ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-100'}`}>
              <h2 className='text-3xl font-semibold ml-5 my-5'>Patienten</h2>
              <div className='px-5 w-full'>
                <input type='text' placeholder='Patienten suchen'
                  className='p-2 pl-5 rounded-xl w-full bg-transparent border-gray-400 border'
                  value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>
              {
                loading === true ? <div className='flex justify-center items-center w-full h-full pb-10'><VscLoading className='text-[4rem] animate-spin text-blue-700' /></div> :
                  filteredPatients.length > 0 ? filteredPatients.map((patient) => (

                    <>
                      <div
                        className={`flex w-full duration-300 cursor-pointer p-3 pl-5 ${themeMode === 'dark' ? 'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(210,210,210)]'} 
                        ${selectedPatient.id === patient._id ? `border-l-8 border-blue-500 ${themeMode === 'dark' ? 'bg-[rgb(100,100,100)]' : 'bg-[rgb(210,210,210)]'}` : ''}`}
                        onClick={() => handlePatientClick(patient._id, patient.name, patient.profilePicture, patient.email, patient.phone)}>
                        <img src={patient.profilePicture ? patient.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'}
                          alt='' className='w-[60px] h-[60px] rounded-full object-cover' />
                        <div className='ml-3 w-full flex justify-between items-center'>
                          <h1 className='text-xl font-semibold ml-1'>{patient.name}</h1>
                          {unreadMessages[patient._id] > 0 && (
                            <p className='p-1 bg-blue-500 text-white rounded-full px-3 mr-3'>{unreadMessages[patient._id]}</p>
                          )}
                        </div>
                      </div>
                      <hr className='border-b border-gray-400 w-full my-[-1rem]' />
                    </>
                  )) : <div className='flex justify-center items-center w-full h-full pb-10'><p className='text-xl '>Keine Patienten gefunden</p></div>
              }
            </div>
            <ChatWindow themeMode={themeMode} selectedPatient={selectedPatient}
              groupedMessages={groupedMessages} inputMessage={inputMessage}
              setInputMessage={setInputMessage} messages={messages} token={token}
              setSelectedPatient={setSelectedPatient}
              handleKeyDown={handleKeyDown} handleSendMessage={handleSendMessage} me={me} />
          </>
      }
    </div>
  );
}