import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { myContext } from "../../../myContext";
import { IoMdArrowBack, IoMdChatboxes } from "react-icons/io";

import { BsClipboard2Plus } from "react-icons/bs";
import Button from "../../Pages/Static/Button";
import moment from "moment";
import FollowUpForm from "./FollowUpForm";
import NewCaseForm from "./NewCaseForm";
import {
  TfiLayoutAccordionMerged,
  TfiLayoutAccordionSeparated,
} from "react-icons/tfi";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { VscLoading } from "react-icons/vsc";
import PatientForm from "./PatientForm";
import EditCaseForm from "./EditCaseForm";
import EditFollowUpForm from "./EditFollowUpForm";

export default function PatientDetails({ token }) {
  const navigate = useNavigate();
  const { themeMode, setSelectedPatient } = React.useContext(myContext);
  const id = useParams().id;
  const [patient, setPatient] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    dob: "",
    profilePicture: "",
  });
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedVisits, setExpandedVisits] = useState({});
  const [followUpForm, setFollowUpForm] = useState({
    show: false,
    mainVisit: "",
    reason: "",
  });
  const [addNewVisit, setAddNewVisit] = useState(false);
  const [mainVisit, setMainVisit] = useState(null);
  const [followUp, setFollowUp] = useState(null);
  const [confirmDeletePatient, setConfirmDeletePatient] = useState({
    patient: null,
    show: false,
  });

  const [editPatient, setEditPatient] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [age, setAge] = useState(0);
  const imageRef = useRef(null);
  const [confirmDeleteVisit, setConfirmDeleteVisit] = useState({
    visit: null,
    show: false,
  });
  const [confirmDeleteFollowUp, setConfirmDeleteFollowUp] = useState({
    followUp: null,
    show: false,
  });
  const handleImageDeselect = () => {
    setSelectedImage(null);
    imageRef.current.value = null;
  };

  const getPatientInfo = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/patient/getPatient/${id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setPatient(res.data.patient);
        setFormData({
          name: res.data.patient.name,
          email: res.data.patient.email,
          phone: res.data.patient.phone,
          profilePicture: res.data.patient.profilePicture,
        });
        const today = new Date().getFullYear();
        const dob = new Date(res.data.patient.dob).getFullYear();

        setAge(today - dob);
        const res2 = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/visit/getVisits/${id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res2.status === 200) {
          setLoading(false);
          setVisits(res2.data);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Etwas ist schiefgelaufen!");
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleExpanded = (mainVisitId) => {
    setExpandedVisits((prevExpandedVisits) => ({
      ...prevExpandedVisits,
      [mainVisitId]: !prevExpandedVisits[mainVisitId],
    }));
  };

  const handleDeletePatient = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/patient/deletePatient/${confirmDeletePatient.patient}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setConfirmDeletePatient({
          patient: null,
          show: false,
        });
        toast.success("Patient erfolgreich gelöscht");
        setLoading(false);
        window.history.back();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleEditPatient = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/patient/updatePatient/${id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setEditPatient(false);
        toast.success("Patient aktualisiert.");
        setFormData({});
        getPatientInfo();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
    setSelectedImage(e.target.files[0]);
  };

  const handlePatientSelect = () => {
    setSelectedPatient({
      id: patient._id,
      name: patient.name,
      profilePicture: patient.profilePicture,
      email: patient.email,
      phone: patient.phone,
    });
    navigate("/chat");
  };
  const handleDeleteVisit = async () => {
    setLoading(true);

    await axios
      .delete(
        `${process.env.REACT_APP_ENDPOINT}/visit/deleteVisit/${confirmDeleteVisit.visit}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setConfirmDeleteVisit({
            visit: null,
            show: false,
          });
          toast.success("Besuch erfolgreich gelöscht");
          setLoading(false);
          getPatientInfo();
        }
      })
      .catch((err) => setLoading(false));
  };

  const handleDeleteFollowUp = async () => {
    setLoading(true);

    await axios
      .delete(
        `${process.env.REACT_APP_ENDPOINT}/visit/deleteFollowUp/${confirmDeleteFollowUp.followUp}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setConfirmDeleteFollowUp({
            followUp: null,
            show: false,
          });
          toast.success("Follow-up erfolgreich gelöscht");
          setLoading(false);
          getPatientInfo();
        }
      })
      .catch((err) => setLoading(false));
  };
  return (
    <div className={`w-full h-screen pl-[17rem] pr-10 pt-24 pb-5 flex`}>
      <div
        className={`flex flex-col transition-all ease-in-out duration-300 w-[500px] h-[565px] items-center py-10 px-5 ${themeMode === "dark" ? "bg-[rgb(40,40,40)]" : "bg-[rgb(245,245,245)]"
          }  rounded-xl`}
      >
        {loading ? (
          <div className={`flex w-full h-full justify-center items-center`}>
            <VscLoading className="animate-spin text-center text-[5rem] text-blue-800" />
          </div>
        ) : (
          <>
            <div className=" border-blue-300 border-dashed border-2 p-5 mb-5 rounded-full">
              <img
                src={
                  patient.profilePicture
                    ? patient.profilePicture
                    : "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
                }
                alt="profile"
                className="w-40 h-40 rounded-full"
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="text-2xl font-semibold">{patient.name}</p>
              <p className="mt-2 text-gray-400">{patient.email}</p>
            </div>
            <div className="flex gap-3 mt-5 justify-center items-center">
              <p
                className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                onClick={handlePatientSelect}
              >
                <IoMdChatboxes />
              </p>
              <p
                className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                onClick={() => setEditPatient(true)}
              >
                <MdEdit />
              </p>
              <p
                className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                onClick={() =>
                  setConfirmDeletePatient({ patient: patient._id, show: true })
                }
              >
                <MdDeleteForever />
              </p>
            </div>
            <div className="flex flex-col mt-5">
              <p className="text-xl font-semibold text-center">
                Persönliche Details
              </p>
              <p className="mt-2 text-gray-400">
                Geburtsdatum: {moment(patient.dob).format("D MMMM, YYYY")}
              </p>
              <p className="mt-2 text-gray-400">Alter: {age}</p>
              <p className="mt-2 text-gray-400">ID: {patient._id}</p>
            </div>
          </>
        )}
      </div>

      <div
        className={`flex flex-col w-full transition-all ease-in-out duration-300 ${loading === true
            ? "h-[500px] overflow-hidden"
            : "max-h-full overflow-y-scroll"
          } 
            items-center p-10 ${themeMode === "dark"
            ? "bg-[rgb(40,40,40)]"
            : "bg-[rgb(245,245,245)]"
          }  rounded-xl ml-10`}
        style={{
          WebkitOverflowScrolling: "touch",
          WebkitScrollbarThumb: "transparent",
          scrollbarWidth: "0px",
        }}
      >
        <div className="flex flex-col justify-start w-full h-full items-center">
          <div className="flex w-full justify-between">
            <div className="flex gap-3">
              <p className="text-2xl font-semibold">Besuchsverlauf</p>
              <p className="mt-2 text-gray-400 flex">
                Gesamtbesuche:{" "}
                {loading === true ? (
                  <div className="animate-spin text-2xl rounded-full h-6 w-6 ml-3 border-b-2 border-blue-500"></div>
                ) : (
                  visits.length
                )}
              </p>
            </div>
            <div className="flex gap-3 text-xl">
              <Button
                icon={<IoMdArrowBack />}
                onClick={() => window.history.back()}
              />
              <Button
                text="Neuer Besuch"
                onClick={() => {
                  setAddNewVisit(true);
                }}
              />
            </div>
          </div>
          {loading ? (
            <div className={`flex w-full h-full justify-center items-center`}>
              <VscLoading className="animate-spin text-center text-[5rem] text-blue-800" />
            </div>
          ) : visits.length === 0 ? (
            <p className="text-2xl mt-10">Noch keine Besuche!</p>
          ) : (
            <>
              <div className="flex justify-evenly w-full overflow-y-auto rounded">
                <div className="flex flex-col w-full">
                  <table className="w-full mt-5">
                    <thead>
                      <tr>
                        <th className="border-b pb-3 text-lg">Grund</th>
                        <th className="border-b pb-3 text-lg">Datum</th>
                        <th className="border-b pb-3 text-lg">Standort</th>
                        <th className="border-b pb-3 text-lg">Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                      {visits
                        .filter(
                          (mainVisit) => mainVisit.visitType === "NewCase"
                        )
                        .map((mainVisit) => (
                          <React.Fragment key={mainVisit._id}>
                            <tr
                              className="cursor-pointer border-b border-gray-400 relative"
                              onClick={() => toggleExpanded(mainVisit._id)}
                            >
                              <td className="px-10 py-3 text-center">
                                <span className="text-center text-2xl absolute left-4">
                                  {expandedVisits[mainVisit._id] ? (
                                    <TfiLayoutAccordionSeparated />
                                  ) : (
                                    <TfiLayoutAccordionMerged />
                                  )}
                                </span>
                                {mainVisit.reason}
                              </td>
                              <td className="py-3 text-center">
                                {moment
                                  .utc(mainVisit.dateTime)
                                  .format("MMMM D, YYYY hh:mm A")}
                              </td>
                              <td className="py-3 text-center"> {mainVisit.location}</td>
                              <td className="py-3 flex justify-center gap-x-2">
                                <button
                                  className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                                  onClick={() => {
                                    setMainVisit(mainVisit);
                                  }}
                                >
                                  <MdEdit />
                                </button>
                                <button
                                  className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                                  onClick={() =>
                                    setConfirmDeleteVisit({
                                      visit: mainVisit._id,
                                      show: true,
                                    })
                                  }
                                >
                                  <MdDeleteForever />
                                </button>
                                <button
                                  className="text-2xl p-2 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                                  onClick={() =>
                                    setFollowUpForm({
                                      show: true,
                                      mainVisit: mainVisit._id,
                                      reason: mainVisit.reason,
                                    })
                                  }
                                >
                                  <BsClipboard2Plus />
                                </button>

                                {/* <Button
                                  text="Folgebesuch"
                                  onClick={() =>
                                    setFollowUpForm({
                                      show: true,
                                      mainVisit: mainVisit._id,
                                      reason: mainVisit.reason,
                                    })
                                  }
                                /> */}
                              </td>
                            </tr>
                            {expandedVisits[mainVisit._id] && (
                              <tr className="w-full">
                                <td colSpan="3" className="pl-10">
                                  {visits
                                    .filter(
                                      (followUpVisit) =>
                                        followUpVisit.mainVisit ===
                                        mainVisit._id &&
                                        followUpVisit.visitType === "FollowUp"
                                    )
                                    .map((followUpVisit) => (
                                      <React.Fragment key={followUpVisit._id}>
                                        <tr className="flex justify-between w-full">
                                          <td className="p-3 text-lg">
                                            {followUpVisit.reason}
                                          </td>
                                          <td className="p-3 text-gray-400 flex-col gap-1">
                                            {moment
                                              .utc(followUpVisit.dateTime)
                                              .format("MMMM D, YYYY hh:mm A")}
                                          </td>
                                        </tr>
                                        <tr className="flex items-center justify-between w-full border-b border-gray-400 px-3 pb-3">
                                          <td
                                            colSpan="3"
                                            className=" text-gray-400"
                                          >
                                            <div className="flex flex-col gap-3">
                                              <p>Notizen: {followUpVisit.notes}</p>
                                              <p>Standort: {followUpVisit.location}</p>
                                            </div>
                                          </td>
                                          <td className="flex gap-2">
                                            <button
                                              className="p-1 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                                              onClick={() => {
                                                setFollowUp(followUpVisit);
                                              }}
                                            >
                                              <MdEdit />
                                            </button>
                                            <button
                                              className="p-1 border-gray-400 border rounded-xl transition cursor-pointer hover:border-blue-500 hover:text-blue-500 hover:scale-105"
                                              onClick={() =>
                                                setConfirmDeleteFollowUp({
                                                  followUp: followUpVisit._id,
                                                  show: true,
                                                })
                                              }
                                            >
                                              <MdDeleteForever />
                                            </button>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                  {visits.filter(
                                    (followUpVisit) =>
                                      followUpVisit.mainVisit ===
                                      mainVisit._id &&
                                      followUpVisit.visitType === "FollowUp"
                                  ).length === 0 && (
                                      <tr
                                        className="flex p-3 w-full border-b border-gray-400"
                                        onClick={() =>
                                          setFollowUpForm({
                                            show: true,
                                            mainVisit: mainVisit._id,
                                            reason: mainVisit.reason,
                                          })
                                        }
                                      >
                                        <p
                                          className={`cursor-pointer duration-200 ${themeMode === "dark"
                                              ? "hover:text-blue-400"
                                              : "hover:text-blue-600"
                                            }`}
                                        >
                                          Keine Folgebesuche gefunden, möchten Sie
                                          einen hinzufügen?
                                        </p>
                                      </tr>
                                    )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {confirmDeletePatient.show === true && (
        <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
          <div
            className=" w-[500px] h-[220px] backdrop-blur-[15px] rounded-xl shadow-2xl"
            style={{
              backgroundColor: `${themeMode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(255,255,255,0.5)"
                }`,
            }}
          >
            <div className="flex flex-col justify-between p-10">
              <div className="flex flex-col">
                <p className="text-2xl">Patient löschen?</p>
                <p>
                  Sind Sie sicher, dass Sie diesen Patienten löschen möchten?
                </p>
              </div>
              <div className="flex justify-end w-full gap-5 mt-10">
                <Button
                  text="Abbrechen"
                  onClick={() =>
                    setConfirmDeletePatient({
                      ...confirmDeletePatient,
                      show: false,
                    })
                  }
                />
                {loading ? (
                  <Button
                    text="Warten..."
                    icon={<VscLoading />}
                    spinner={true}
                    disabled={true}
                  />
                ) : (
                  <Button text="Löschen" onClick={handleDeletePatient} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {followUpForm.show && (
        <FollowUpForm
          token={token}
          mainVisit={followUpForm.mainVisit}
          setFollowUpForm={setFollowUpForm}
          getPatientInfos={getPatientInfo}
          loading={loading}
          setLoading={setLoading}
          followUpForm={followUpForm}
          patient={id}
        />
      )}
      {addNewVisit && (
        <NewCaseForm
          token={token}
          patient={id}
          setAddNewVisit={setAddNewVisit}
          getPatientInfos={getPatientInfo}
        />
      )}
      {mainVisit && (
        <EditCaseForm
          token={token}
          patient={id}
          mainVisit={mainVisit}
          setMainVisit={setMainVisit}
          getPatientInfos={getPatientInfo}
        />
      )}
      {followUp && (
        <EditFollowUpForm
          token={token}
          patient={id}
          followUp={followUp}
          setFollowUp={setFollowUp}
          loading={loading}
          setLoading={setLoading}
          getPatientInfos={getPatientInfo}
        />
      )}
      {editPatient && (
        <PatientForm
          token={token}
          setModal={setEditPatient}
          text="Edit Patient"
          handleFileChange={handleFileChange}
          loading={loading}
          formData={formData}
          setFormData={setFormData}
          handleEdit={handleEditPatient}
          patient={patient}
          imageRef={imageRef}
          handleImageDeselect={handleImageDeselect}
          selectedImage={selectedImage}
        />
      )}
      {confirmDeleteVisit.show === true && (
        <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
          <div
            className=" w-[500px] h-[220px] backdrop-blur-[15px] rounded-xl shadow-2xl"
            style={{
              backgroundColor: `${themeMode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(255,255,255,0.5)"
                }`,
            }}
          >
            <div className="flex flex-col justify-between p-10">
              <div className="flex flex-col">
                <p className="text-2xl">Besuch löschen?</p>
                <p>Sind Sie sicher, dass Sie diesen Besuch löschen möchten?</p>
              </div>
              <div className="flex justify-end w-full gap-5 mt-10">
                <Button
                  text="Abbrechen"
                  onClick={() =>
                    setConfirmDeleteVisit({
                      ...confirmDeleteVisit,
                      show: false,
                    })
                  }
                />
                {loading ? (
                  <Button
                    text="Warten..."
                    icon={<VscLoading />}
                    spinner={true}
                    disabled={true}
                  />
                ) : (
                  <Button text="Löschen" onClick={handleDeleteVisit} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmDeleteFollowUp.show === true && (
        <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
          <div
            className=" w-[500px] h-[220px] backdrop-blur-[15px] rounded-xl shadow-2xl"
            style={{
              backgroundColor: `${themeMode === "dark"
                  ? "rgba(255,255,255,0.1)"
                  : "rgba(255,255,255,0.5)"
                }`,
            }}
          >
            <div className="flex flex-col justify-between p-10">
              <div className="flex flex-col">
                <p className="text-2xl">Folgebesuch löschen?</p>
                <p>
                  Sind Sie sicher, dass Sie diesen Folgebesuch löschen möchten?
                </p>
              </div>
              <div className="flex justify-end w-full gap-5 mt-10">
                <Button
                  text="Abbrechen"
                  onClick={() =>
                    setConfirmDeleteFollowUp({
                      ...confirmDeleteFollowUp,
                      show: false,
                    })
                  }
                />
                {loading ? (
                  <Button
                    text="Warten..."
                    icon={<VscLoading />}
                    spinner={true}
                    disabled={true}
                  />
                ) : (
                  <Button text="Löschen" onClick={handleDeleteFollowUp} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
