import React from 'react';
import { myContext } from '../../../myContext';
import Button from '../../Pages/Static/Button';
import axios from 'axios';
import { toast } from 'react-hot-toast';

export default function PushAllForm({ token, setModalOpen, modalOpen }) {
    const { themeMode } = React.useContext(myContext);
    const [message, setMessage] = React.useState('');
    const [title, setTitle] = React.useState('');

    const sendNotification = async (e) => {
        e.preventDefault();

        if (message === '') return toast.error('Bitte geben Sie eine Nachricht ein!');
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/patient/sendNotificationAll`, { message, title }, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                toast.success('Benachrichtigung erfolgreich gesendet!');
                setModalOpen(false);
            } else {
                toast('Benachrichtigung wurde an einige gesendet, während sie für andere fehlgeschlagen ist.')
                setModalOpen(false);
            }
        } catch (error) {
            toast.error('Benachrichtigung konnte nicht gesendet werden!');
            console.error(error);
        }
    };

    return (
        <div className='absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center'>
            <div className=' w-auto max-h-screen overflow-y-auto backdrop-blur-[10px] rounded-xl shadow-2xl '
                style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.1)'}` }}>
                <div className='flex flex-col p-10 text-lg'>
                    <span className='text-2xl font-bold mb-5'>Push-Benachrichtigung</span>
                    <span className='mb-5'>Senden Sie eine Push-Benachrichtigung an alle Benutzer.</span>
                    <input className="p-3 rounded-xl bg-transparent border-gray-400 border w-full mb-5"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Titel"
                    />
                    <textarea className='p-2 rounded-xl bg-transparent border-gray-400 border w-full h-80 resize-none'
                        value={message} onChange={(e) => setMessage(e.target.value)}
                        placeholder='Hier Nachricht eingeben...' />
                    <div className='flex justify-end mt-5 gap-3'>
                        <Button text='Abbrechen' onClick={() => setModalOpen(false)} />
                        <Button text='Senden' onClick={sendNotification} />
                    </div>
                </div>
            </div>
        </div>
    );
}