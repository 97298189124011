import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function HamBurgor({ hamOpened, setHamOpened, menuRef, themeMode, selectedPatient, setEditPatient, setConfirmDelete }) {
    const navigate = useNavigate();

    return (
        <div ref={menuRef}>
            <button onClick={() => setHamOpened(!hamOpened)} className="flex relative flex-col gap-1 justify-center items-center z-40">
                <span className={`${themeMode === 'dark' ? 'bg-white' : 'bg-gray-500'} block transition-all duration-300 ease-out 
                    h-0.5 w-8 rounded-sm ${hamOpened ?
                        'rotate-45 translate-y-1' : '-translate-y-0.5'
                    }`} >
                </span>
                <span className={`${themeMode === 'dark' ? 'bg-white' : 'bg-gray-500'} block transition-all duration-300 ease-out 
                    h-0.5 w-8 rounded-sm my-0.5 ${hamOpened ?
                        'opacity-0 rotate-45' : 'opacity-100'
                    }`} >
                </span>
                <span className={`${themeMode === 'dark' ? 'bg-white' : 'bg-gray-500'} block transition-all duration-300 ease-out
                                h-0.5 w-8 rounded-sm ${hamOpened ?
                        '-rotate-45 -translate-y-3' : 'translate-y-0.5'
                    }`} >
                </span>
            </button>
            <div className={`absolute w-[200px] z-10 transition-all duration-300 ease-out top-10 right-0 h-[200px] backdrop-blur-[30px] 
                            rounded-xl shadow-2xl ${hamOpened ? 'translate-y-5 translate-x-[-4.5rem] opacity-100' : 'translate-y-[-2.5rem] translate-x-[-0rem] opacity-0'}`}
                style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.5)'}` }}
            >
                {hamOpened ?
                    <ul className='flex flex-col gap-3 py-5'>
                        <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                            'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                            onClick={() => navigate(`/dashboard/patients/${selectedPatient.id}`)}
                        >
                            <p className='text-xl'>Profil anzeigen</p>
                        </li>
                        <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                            'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                            onClick={() => setEditPatient({ show: true, patientId: selectedPatient.id })}
                        >
                            <p className='text-xl'>Profil bearbeiten</p>
                        </li>
                        <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                            'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                            onClick={() => setConfirmDelete({ show: true, patient: selectedPatient.id })}
                        >
                            <p className='text-xl'>Profil löschen</p>
                        </li>
                    </ul>
                    : null}
            </div>
        </div>
    );
}