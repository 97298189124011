import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineChatBubbleOutline, MdOutlineSpaceDashboard } from "react-icons/md";
import { HiOutlineUserGroup, HiOutlineUserCircle } from "react-icons/hi2";
import { PiNotificationFill } from "react-icons/pi";
import { myContext } from '../../myContext';
import { IoIosLogOut } from 'react-icons/io';
import axios from 'axios';
import { FaExclamation } from 'react-icons/fa';

export default function SideNav({ token, setLoggedIn, me }) {
    const { themeMode, notificationReceived, setNotificationReceived } = React.useContext(myContext);
    const location = useLocation();
    const [currentUrl, setCurrentUrl] = useState(location.pathname);

    useEffect(() => {
        setCurrentUrl(location.pathname);
    }, [location.pathname]);

    const handleLogout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/auth/logout`, {},
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                localStorage.removeItem('jwtToken');
                localStorage.removeItem('jwtTokenExpiration');
                setLoggedIn(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchMessages = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ENDPOINT}/message/getUnreadMessages`,
                {
                    params: {
                        senderId: me._id,
                    },
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const { hasUnreadMessages } = response.data;
            setNotificationReceived(hasUnreadMessages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fetchMessages();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`fixed z-40 transition-all w-[260px] duration-300 ease-in-out ${themeMode === "light" ? "bg-slate-200 text-black" : "bg-[rgb(20,20,20)] text-white"}`}>
            <div className='flex flex-col justify-between items-center pt-20 px-5 h-screen border-r-2 border-gray-500'>
                <ul className='flex flex-col gap-5 pt-5'>
                    <Link to='/dashboard'>
                        <li className={` ${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'} 
                        ${currentUrl === '/dashboard' ? themeMode === "dark" ? 'bg-gray-600 border-l-4 border-blue-400' : 'bg-gray-300 border-l-4 border-blue-400' : ''}
                        flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}>
                            <MdOutlineSpaceDashboard className={`${currentUrl === '/dashboard' ? 'text-blue-400' : ''} text-2xl`} />
                            <p className={`${currentUrl === '/dashboard' ? 'text-blue-400' : themeMode === 'light' ? 'text-gray-500' : 'text-gray-300'} ml-5 text-xl `}>Dashboard</p>
                        </li>
                    </Link>
                    <Link to='/dashboard/patients'>
                        <li className={`${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'} 
                        ${currentUrl === '/dashboard/patients' ? themeMode === "dark" ? 'bg-gray-600 border-l-4 border-blue-400' : 'bg-gray-300 border-l-4 border-blue-400' : ''}
                        flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}>
                            <HiOutlineUserGroup className={`${currentUrl === '/dashboard/patients' ? 'text-blue-400' : ''} text-2xl`} />
                            <p className={`${currentUrl === '/dashboard/patients' ? 'text-blue-400' : themeMode === 'light' ? 'text-gray-500' : 'text-gray-300'} ml-5 text-xl `}>Patienten</p>
                        </li>
                    </Link>
                    <Link to='/chat'>
                        <li className={`${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'}
                        ${currentUrl === '/chat' ? themeMode === "dark" ? 'bg-gray-600 border-l-4 border-blue-400' : 'bg-gray-300 border-l-4 border-blue-400' : ''}
                         flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}>
                            <div className='flex relative items-center'>
                                <MdOutlineChatBubbleOutline className={`${currentUrl === '/chat' ? 'text-blue-400' : ''} text-2xl mt-1`} />
                                {
                                    notificationReceived &&
                                    <div className='absolute -top-2 -right-2 bg-red-500 rounded-full w-5 h-5 flex justify-center items-center'>
                                        <FaExclamation className='text-white text-xs' />
                                    </div>
                                }
                            </div>
                            <p className={`${currentUrl === '/chat' ? 'text-blue-400' : themeMode === 'light' ? 'text-gray-500' : 'text-gray-300'} ml-5 text-xl`}>Chat</p>
                        </li>
                    </Link>
                    <Link to='/dashboard/notifications'>
                        <li className={`${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'} 
                        ${currentUrl === '/dashboard/notifications' ? themeMode === "dark" ? 'bg-gray-600 border-l-4 border-blue-400' : 'bg-gray-300 border-l-4 border-blue-400' : ''}
                        flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}>
                            <PiNotificationFill className={`${currentUrl === '/dashboard/notifications' ? 'text-blue-400' : ''} text-2xl`} />
                            <p className={`${currentUrl === '/dashboard/notifications' ? 'text-blue-400' : themeMode === 'light' ? 'text-gray-500' : 'text-gray-300'} ml-5 text-xl `}>Benachrichtigungen</p>
                        </li>
                    </Link>
                </ul>
                <ul className='flex flex-col w-full gap-5 mb-10'>
                    <Link to='/profile'>
                        <li className={`${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'} 
                        ${currentUrl === '/profile' ? themeMode === "dark" ? 'bg-gray-600 border-l-4 border-blue-400' : 'bg-gray-300 border-l-4 border-blue-400' : ''}
                        flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}>
                            <HiOutlineUserCircle className={`${currentUrl === '/profile' ? 'text-blue-400' : ''} text-4xl`} />
                            <p className={`${currentUrl === '/profile' ? 'text-blue-400' : themeMode === 'light' ? 'text-gray-500' : 'text-gray-300'} ml-5 text-xl `}>Profil</p>
                        </li>
                    </Link>
                    <li className={`${themeMode === "dark" ? 'hover:bg-gray-600' : 'hover:bg-gray-300'} text-xl text-red-500 flex items-center cursor-pointer transition-all duration-300 ease-in-out p-3 rounded-md`}
                        style={{
                            textShadow: '0px 0px 10px rgba(255,0,0,0.5)'
                        }}
                        onClick={handleLogout}
                    >
                        <IoIosLogOut className='mt-1 text-3xl' />
                        <p className='ml-6'>Abmelden</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}