import React, { useState, useEffect } from 'react';
import { myContext } from '../../../myContext';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { HiUserCircle } from 'react-icons/hi2';
import { FaKey } from 'react-icons/fa6';
import ProfileInfo from './ProfileInfo';
import PasswordChange from './PasswordChange';

export default function Profile({ token, getMe, me, setLoggedIn }) {
  const { themeMode } = React.useContext(myContext);
  const [form, setForm] = useState({
    name: "",
    email: "",
    currentPass: "",
    newPass: "",
  });
  const [activeTab, setActiveTab] = useState('info');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (me) {
      setForm({
        name: me.name,
        email: me.email,
        currentPass: "",
        newPass: "",
      });
    }
  }, [me]);

  const handleProfileUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/auth/updateProfile`,
        {
          name: form.name,
          email: form.email,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success('Profil erfolgreich aktualisiert!');
        getMe();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('Etwas ist schief gelaufen!');
    }
  };

  const handlePasswordChange = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/auth/updatePassword`,
        {
          currentPass: form.currentPass,
          newPass: form.newPass,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success('Passwort erfolgreich geändert!');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtTokenExpiration');
        setLoggedIn(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('Ungültiges Passwort oder Serverfehler.');
    }
  };

  return (
    <div className={`w-full h-screen pl-24 pt-14 flex justify-center items-center overflow-y-auto transition-all ease-in-out duration-300 ${themeMode === 'dark' ? 'bg-[rgb(40,40,40)]' : 'bg-[rgb(245,245,245)]'} rounded-xl`}>
      <div>
        <p className='text-4xl font-bold'>Profil</p>
        <div className='flex gap-7 items-center mt-10'>
          <p
            className={`flex gap-3 items-center border-b-2 pb-2 transition-all duration-300 ${activeTab === 'info' ? 'border-blue-500' : 'border-transparent'
              } cursor-pointer`}
            onClick={() => setActiveTab('info')}
          >
            <span className='text-3xl'>
              <HiUserCircle />
            </span>
            <span className='font-bold text-xl'>Info</span>
          </p>
          <p
            className={`flex gap-3 items-center border-b-2 pb-2 transition-all duration-300 ${activeTab === 'password' ? 'border-blue-500' : 'border-transparent'
              } cursor-pointer`}
            onClick={() => setActiveTab('password')}
          >
            <span className='text-xl'>
              <FaKey />
            </span>
            <span className='font-bold text-xl'>Passwort</span>
          </p>
        </div>
        {activeTab === 'info' && <ProfileInfo themeMode={themeMode} form={form} setForm={setForm} loading={loading} onClick={handleProfileUpdate} />}
        {activeTab === 'password' && <PasswordChange themeMode={themeMode} form={form} setForm={setForm} loading={loading} onClick={handlePasswordChange} />}
      </div>
    </div>
  );
}