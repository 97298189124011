import React, { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import { myContext } from "../../../myContext";
import Button from "../Static/Button";

export default function Login({ loggedIn, setLoggedIn }) {
  const { themeMode } = useContext(myContext)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData({ ...formData, [name]: inputValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/auth/loginPhone`, formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setSuccess(true);

          const jwtToken = response.data.token;

          const expirationTime = formData.rememberMe ? 7 * 24 * 60 * 60 * 1000 : 24 * 60 * 60 * 1000;
          const expirationDate = new Date().getTime() + expirationTime;

          localStorage.setItem("jwtToken", jwtToken);
          localStorage.setItem("jwtTokenExpiration", expirationDate);
          setTimeout(() => {
            setLoggedIn(true);
          }, 1000)
        } else {
          setSuccess(false);
        }
      } catch (error) {
        setSuccess(false);
        console.error(error);
      }
    }
    else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/auth/login`, formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          setSuccess(true);

          const jwtToken = response.data.token;

          const expirationTime = formData.rememberMe ? 7 * 24 * 60 * 60 * 1000 : 24 * 60 * 60 * 1000;
          const expirationDate = new Date().getTime() + expirationTime;

          localStorage.setItem("jwtToken", jwtToken);
          localStorage.setItem("jwtTokenExpiration", expirationDate);
          setTimeout(() => {
            setLoggedIn(true);
          }, 1000)
        } else {
          setSuccess(false);
        }
      } catch (error) {
        setSuccess(false);
        console.error(error);
      }
    }
  };

  return (
    <div className="flex justify-center items-center h-screen w-full">
      <div className={`transition-all ease-in-out duration-300 ${themeMode === 'light' ? 'bg-gray-300' : 'bg-[rgb(30,30,30)]'} p-10 rounded-xl w-[450px]`}>
        <p className="text-4xl font-semibold mb-6 mt-2 text-center">Anmelden</p>
        {success === null ? (
          <div className="p-1 px-5"></div>
        ) : success === false ? (
          <div className="bg-red-400 text-lg font-semibold p-1 px-5 rounded-lg">
            Ungültige Anmeldedaten
          </div>
        ) : (
          <div className="bg-green-400 text-lg font-semibold p-1 px-5 rounded-lg">
            Anmeldung erfolgreich
          </div>
        )}
        <form
          className="flex flex-col items-center w-full"
          onSubmit={handleSubmit}
        >
          <div className="mt-5">
            <label className="text-lg font-semibold">E-Mail</label>
            <input
              name="email"
              className="w-[380px] mt-2 p-1 px-2 rounded-lg text-black"
              type="text"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="mt-5">
            <label className="text-lg font-semibold">Passwort</label>
            <input
              name="password"
              className="w-[380px] mt-2 p-1 px-2 rounded-lg text-black"
              type="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="mt-5">
            <label className="text-lg font-semibold text-center">
              <input
                name="rememberMe"
                type="checkbox"
                className="h-4 w-4"
                checked={formData.rememberMe}
                onChange={handleChange}
              />
              &nbsp;Erinnere dich an mich
            </label>
          </div>
          <div className="mt-5 flex justify-center">
            <Button text="Anmelden" typeButton="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}