import React, { useContext } from 'react';
import { myContext } from '../../../myContext';

function Button({ text, typeButton, onClick, icon, disabled, spinner, onKeyDown }) {
    const { themeMode } = useContext(myContext);
    return (
        <button
            className={`transition-all ease-in-out duration-300 shadow-lg
            flex justify-center items-center gap-2
          text-white font-bold py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline 
            ${themeMode === 'dark' ? 'bg-blue-800 hover:bg-blue-600' : 'bg-blue-700 hover:bg-blue-500'}
          `}
            type={typeButton || 'button'}
            onClick={onClick || null}
            onKeyDown={onKeyDown || null}
            disabled={disabled || false}
        >
            {icon && (
                <div className='flex justify-center items-center'>
                    {spinner ? (
                        <div className="animate-spin text-2xl rounded-full h-5 w-5 border-b-2 border-white">
                        </div>
                    ) : (
                        <div>{icon}</div>
                    )}
                </div>
            )}
            {text}
        </button>
    );
}

export default Button;
