import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";
import Button from "../../Pages/Static/Button";
import { myContext } from "../../../myContext";

export default function FollowUpForm({
  token,
  mainVisit,
  setFollowUpForm,
  loading,
  setLoading,
  followUpForm,
  patient,
  getPatientInfos,
}) {
  const { themeMode } = React.useContext(myContext);
  const [formData, setFormData] = useState({
    patient: "",
    dateTime: "",
    notes: "",
    reason: "",
    location: "Aurich",
  });
  const [patientInfo, setPatientInfo] = useState({});

  const getPatientInfo = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/patient/getPatient/${patient}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setPatientInfo(res.data.patient);
        const res2 = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/visit/getVisits/${patient}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res2.status === 200) setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Etwas ist schiefgelaufen!");
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  const scheduleFollowUp = async () => {
    if (formData.dateTime === "")
      return toast.error("Bitte wählen Sie ein Datum und eine Uhrzeit aus");
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/visit/addFollowUp`,
        {
          patient: patient,
          reason: `Follow up for ${followUpForm.reason}`,
          dateTime: formData.dateTime,
          notes: formData.notes,
          mainVisitId: mainVisit,
          location: formData.location,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        getPatientInfos();
        toast.success("Nachfolgebesuch erfolgreich geplant");
        setFollowUpForm(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Etwas ist schiefgelaufen!");
    }
  };

  return (
    <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
      <div
        className=" w-auto h-auto backdrop-blur-[10px] rounded-xl shadow-2xl "
        style={{
          backgroundColor: `${themeMode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(255,255,255,0.5)"
            }`,
        }}
      >
        <div className="flex flex-col p-10 text-lg">
          <div className="flex justify-start items-center">
            <p className="text-2xl mr-5 ">Nachfolgebesuch für:</p>
            <div className="flex mt-3 justify-start items-center bg-blue-800 px-3 py-1 rounded-full w-[210px] h-[60px]">
              {loading === true ? (
                <VscLoading className="animate-spin text-2xl text-white" />
              ) : (
                <img
                  src={
                    patientInfo.profilePicture
                      ? patientInfo.profilePicture
                      : "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
                  }
                  alt="profile"
                  className="w-[40px] h-[40px] rounded-full"
                />
              )}
              <div className="flex flex-col ml-4 text-white">
                {loading === true ? (
                  <VscLoading className="animate-spin text-center ml-10 text-2xl text-white" />
                ) : (
                  <>
                    <p>{patientInfo.name}</p>
                    <p>{patientInfo.phone}</p>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-10">
            <div>
              <div className="flex gap-5 mt-5">
                <div className="flex flex-col w-[400px]">
                  <label className="text-2xl">
                    Nachfolge für{" "}
                    <span className="border-b pb-[0.1rem]">
                      {followUpForm.reason}
                    </span>
                  </label>
                </div>
              </div>

              <div className="flex flex-col mt-5">
                <label className="">Datum und Uhrzeit</label>
                <input
                  type="datetime-local"
                  name="dateTime"
                  placeholder="Datum und Uhrzeit"
                  className="mt-2 p-2 px-4 rounded-xl bg-transparent border"
                  value={formData.dateTime}
                  onChange={(e) =>
                    setFormData({ ...formData, dateTime: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-5">
                <label className="">Notizen</label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Notizen"
                  className="mt-2 p-2 px-4 h-[200px] resize-none rounded-xl bg-transparent border"
                  value={formData.notes}
                  onChange={(e) =>
                    setFormData({ ...formData, notes: e.target.value })
                  }
                />
              </div>
              <div className="flex flex-col mt-5">
                <label className="">Standort</label>
                <select
                  name='location'
                  className='mt-2 p-2 px-4 rounded-xl bg-transparent border'
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                >
                  <option className='text-black' value='Aurich'>Aurich</option>
                  <option className='text-black' value='Wittmund'>Wittmund</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end w-full gap-5 px-10 pb-10 duration-300">
          <Button text="Abbrechen" onClick={() => setFollowUpForm(false)} />
          {loading === true ? (
            <Button
              text="Warten..."
              icon={<VscLoading />}
              spinner={true}
              disabled={true}
            />
          ) : (
            <>
              <Button text="Senden" onClick={scheduleFollowUp} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
{
  /* <div className='flex flex-col'>
                                    <label>Andere Gründe angeben</label>
                                    <input
                                        type='text'
                                        name='otherReason'
                                        className={`p-2 px-4 mt-2 rounded-xl border ${formData.reason !== 'Andere Gründe' ? 'bg-gray-400 cursor-not-allowed' : 'bg-transparent'
                                            }`}
                                        disabled={formData.reason !== 'Andere Gründe'}
                                        value={formData.otherReason}
                                        onChange={handleOtherReasonChange}
                                    />
                                </div> */
}
{
  /* <select
                                        name='reason'
                                        className='mt-2 p-2 px-4 rounded-xl bg-transparent border'
                                        value={formData.reason}
                                        onChange={handleReasonChange}
                                    >
                                        <option className='text-black' value=''>Wählen Sie einen Grund aus</option>
                                        <option className='text-black' value='Zahnfüllung'>Zahnfüllung</option>
                                        <option className='text-black' value='Prothesen'>Prothesen</option>
                                        <option className='text-black' value='Wurzelkanal'>Wurzelkanalbehandlung</option>
                                        <option className='text-black' value='Zahnkrone'>Zahnkrone</option>
                                        <option className='text-black' value='Zahnimplantat'>Zahnimplantat</option>
                                        <option className='text-black' value='Andere Gründe'>Andere Gründe</option>
                                    </select> */
}
