import React, { useEffect, useState, useRef } from 'react';
import { myContext } from '../../../myContext';
import { FaSearch } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import axios from 'axios';
import Button from '../../Pages/Static/Button';
import toast from 'react-hot-toast';
import { VscLoading } from 'react-icons/vsc';
import PatientForm from './PatientForm';
import NewCaseForm from './NewCaseForm';
import PatientListTable from './PatientListTable';

export default function PatientsList({ token }) {
  const { themeMode } = React.useContext(myContext);
  const [patients, setPatients] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({
    patient: null,
    show: false,
  });
  const [addPatient, setAddPatient] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    reason: '',
    dob: '',
    otherReason: '',
    profilePicture: null,
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const imageRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [addNewCase, setAddNewCase] = useState({
    show: false,
    patientId: null,
  });
  const [editPatient, setEditPatient] = useState({
    show: false,
    patientId: null,
  });
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    phone: '',
    dob: '',
    profilePicture: null,
  });
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleDropdownToggle = (patientId) => {
    setOpenDropdowns((prevDropdowns) =>
      prevDropdowns.includes(patientId)
        ? prevDropdowns.filter((id) => id !== patientId)
        : [...prevDropdowns, patientId]
    );
  };

  const getPatients = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/patient/getPatients`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        setPatients(res.data.patients);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/patient/deletePatient/${confirmDelete.patient}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        getPatients();
        setConfirmDelete({
          patient: null,
          show: false,
        });
        toast.success('Patient erfolgreich gelöscht');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddPatient = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (
      !formData ||
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.reason
    ) {
      toast.error('Bitte füllen Sie alle Felder aus');
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/patient/createPatientAdmin`,
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          dob: formData.dob,
          visitReason:
            formData.reason === 'Other reason'
              ? formData.otherReason
              : formData.reason,
          profilePicture: formData.profilePicture,
          visitDateTime: new Date(),
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res.status === 200) {
        toast.success('Patient zur Datenbank hinzugefügt.');
        setAddPatient(false);
        setFormData({});
        getPatients();
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error('Patient existiert bereits oder es gab einen Fehler');
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const newFormData = new FormData();
      newFormData.append('name', editFormData.name);
      newFormData.append('phone', editFormData.phone);
      newFormData.append('dob', editFormData.dob);
      if (selectedImage) {
        newFormData.append('profilePicture', selectedImage);
      }
      const res = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/patient/updatePatient/${editPatient.patientId}`,
        newFormData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setEditPatient(false);
        toast.success('Patient aktualisiert.');
        setFormData({});
        getPatients();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleEditPatient = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/patient/getPatient/${id}`,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setEditFormData({
          name: res.data.patient.name,
          email: res.data.patient.email,
          phone: res.data.patient.phone,
          profilePicture: res.data.patient.profilePicture,
        });
        setEditPatient({
          show: true,
          patientId: id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
    setSelectedImage(e.target.files[0]);
  };

  const handleImageDeselct = () => {
    setSelectedImage(null);
    imageRef.current.value = null;
  };

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      reason: value,
      otherReason: value === 'Other reason' ? '' : formData.otherReason,
    });
  };

  const handleOtherReasonChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      otherReason: value,
    });
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      <div
        className={`w-full h-[100vh] pl-[18rem] pr-10 py-24 pt-[7rem] overflow-y-auto transition-all ease-in-out duration-300 ${themeMode === 'dark'
            ? 'bg-[rgb(40,40,40)]'
            : 'bg-[rgb(245,245,245)]'
          } rounded-xl`}
      >
        <div className='flex gap-5 w-full'>
          <div className='w-full'>
            <div className='flex justify-between w-full'>
              <p className='text-2xl pl-2'>Patientenliste</p>
              <div className='flex gap-2 text-lg items-center relative'>
                <FaSearch className=' absolute left-3' />
                <input
                  type='text'
                  placeholder='Suche'
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  className='border-2 border-gray-300 bg-transparent transition-all ease-in-out duration-300 rounded-lg px-2 pl-9 py-1 mr-5'
                />
                <Button
                  text='Patient hinzufügen'
                  icon={<MdAdd />}
                  onClick={() => setAddPatient(!addPatient)}
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={`flex w-full h-full justify-center items-center`}>
            <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
          </div>
        ) : (
          <PatientListTable
            patients={patients}
            handleEditPatient={handleEditPatient}
            handleDeletePatient={setConfirmDelete}
            confirmDelete={confirmDelete}
            openDropdowns={openDropdowns}
            handleDropdownToggle={handleDropdownToggle}
            addNewCase={addNewCase}
            setAddNewCase={setAddNewCase}
            searchInput={searchInput}
            themeMode={themeMode}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            menuRef={menuRef}
          />
        )}
        {confirmDelete.show === true && (
          <div className='absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center'>
            <div
              className=' w-[400px] h-[220px] backdrop-blur-[15px] rounded-xl shadow-2xl'
              style={{
                backgroundColor: `${themeMode === 'dark'
                    ? 'rgba(255,255,255,0.1)'
                    : 'rgba(255,255,255,0.5)'
                  }`,
              }}
            >
              <div className='flex flex-col justify-between p-10'>
                <div className='flex flex-col'>
                  <p className='text-2xl mb-3'>Patienten löschen?</p>
                  <p>Sind Sie sicher, dass Sie diesen Patienten löschen möchten?</p>
                </div>
                <div className='flex justify-end w-full gap-5 mt-10'>
                  <Button text='Abbrechen' onClick={() => setConfirmDelete(!confirmDelete)} />
                  {loading === true ? (
                    <Button
                      text='Warten...'
                      icon={<VscLoading />}
                      spinner={true}
                      disabled={true}
                    />
                  ) : (
                    <Button text='Löschen' onClick={handleDelete} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {addPatient === true && (
          <PatientForm
            token={token}
            onClick={handleAddPatient}
            formData={formData}
            setFormData={setFormData}
            loading={loading}
            handleReasonChange={handleReasonChange}
            setModal={setAddPatient}
            selectedImage={selectedImage}
            imageRef={imageRef}
            handleImageDeselct={handleImageDeselct}
            handleOtherReasonChange={handleOtherReasonChange}
            handleFileChange={handleFileChange}
            text='Patient hinzufügen'
          />
        )}
        {addNewCase.show === true && (
          <NewCaseForm
            token={token}
            id={addNewCase.patientId}
            setAddNewVisit={setAddNewCase}
            addNewVisit={addNewCase}
            getPatientInfos={getPatients}
          />
        )}
        {editPatient.show === true && (
          <PatientForm
            token={token}
            setModal={setEditPatient}
            text='Patient bearbeiten'
            handleFileChange={handleFileChange}
            loading={loading}
            formData={editFormData}
            setFormData={setEditFormData}
            handleEdit={handleEdit}
            patient={editPatient.patientId}
            imageRef={imageRef}
            handleImageDeselct={handleImageDeselct}
            selectedImage={selectedImage}
          />
        )}
      </div>
    </>
  );
}
