import React, { useEffect, useState, useContext } from 'react'
import { myContext } from '../../../myContext';
import axios from 'axios'
import { GoKebabHorizontal } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import Button from '../../Pages/Static/Button';
import { VscLoading } from "react-icons/vsc";

export default function SmallPatientList({ token }) {
    const navigate = useNavigate();
    const { themeMode } = useContext(myContext);
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [menuOpen, setMenuOpen] = useState({ show: false, id: '' });
    const menuRef = React.useRef();

    const getData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/patient/getPatients`, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setPatients(res.data.patients);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMenuOpen({ show: false, id: '' });
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [menuRef])

    return (
        <>
            <table className='w-[650px] mt-5 text-lg'>
                <thead className='border-b-2 border-gray-300'>
                    <tr className='text-left'>
                        <th className='py-2 pl-5'>Name</th>
                        <th className='py-2'>Telefon</th>
                        <th className='py-2 flex justify-end pr-3'><Button text="Mehr anzeigen" onClick={() => navigate('/dashboard/patients')} /></th>
                    </tr>
                </thead>
                <tbody className='relative'>
                    {loading ? <div className={`flex absolute top-32 w-full h-full justify-center items-center`}>
                        <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
                    </div> :
                        patients.length === 0 ? <p className='text-start px-5 py-2 text-lg'>Keine Patienten gefunden</p> :
                            patients.slice(0, 5).map((patient) => (
                                <tr key={patient._id} className='border-b border-gray-300 break-words truncate'>
                                    <td className='py-2 pl-5 flex gap-3 items-center w-[280px] break-words truncate'>
                                        <img src={patient.profilePicture ? patient.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'}
                                            alt="" className='w-12 object-cover h-12 rounded-full' />
                                        <div className='flex break-words flex-col'>
                                            <span>{patient.name}</span>
                                            <span className='text-gray-400'>{patient.email}</span>
                                        </div></td>
                                    <td className='py-2'>{patient.phone}</td>
                                    <td className="py-2 relative">
                                        <div className="flex gap-2 items-center justify-end pr-5 text-lg">
                                            <div className="relative duration-300" >
                                                <button
                                                    className={`flex relative flex-col gap-1 justify-center items-center z-40 ${themeMode === 'dark' ? 'text-white' : 'text-black'}`}
                                                    onClick={() => setMenuOpen({ show: !menuOpen.show, id: patient._id })}>
                                                    <GoKebabHorizontal className='text-3xl' />
                                                </button>
                                                <div className={`absolute w-[155px] z-50 right-0 backdrop-blur-[10px] 
                                                            rounded-xl shadow-2xl ${menuOpen.show && menuOpen.id === patient._id ? 'block' : 'hidden'}`}
                                                    style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.1)'}` }} >
                                                    {
                                                        menuOpen.show && menuOpen.id === patient._id && (
                                                            <ul className='flex flex-col gap-3 py-2' ref={menuRef}>
                                                                <li className={`cursor-pointer duration-300 px-5 py-2 rounded ${themeMode === 'dark' ?
                                                                    'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                                                                    onClick={() => navigate(`/dashboard/patients/${patient._id}`)}>
                                                                    <p className='text-lg'>Profil ansehen</p>
                                                                </li>
                                                            </ul>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
        </>

    )
}