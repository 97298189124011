import React, { useContext, useEffect, useState } from 'react'
import { myContext } from '../../../myContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import Button from '../../Pages/Static/Button';
import PushAllForm from './PushAllForm';
import NotificationSingle from './NotificationSingle';
import { MdMessage } from 'react-icons/md';
import { VscLoading } from 'react-icons/vsc';

export default function NotificationList({ token }) {
    const { themeMode } = useContext(myContext);
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modalOpen, setModalOpen] = useState(false)
    const [singleModalOpen, setSingleModalOpen] = useState({ show: false, patientId: '' })
    const navigate = useNavigate();

    const patientsPerPage = 7;
    const totalPatients = patients.length;
    const totalPages = Math.ceil(totalPatients / patientsPerPage);
    const pageRangeDisplayed = 5;
    const pages = [];

    const getPatient = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/patient/getPatients`, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setPatients(response.data.patients);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getPatient();
    }, []);

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    let indexOfLastPatient = currentPage * patientsPerPage;
    let indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    let filteredPatients = [];

    if (searchInput !== '') {
        filteredPatients = patients.filter((patient) =>
            patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            patient.email.toLowerCase().includes(searchInput.toLowerCase())
        );

        const adjustedTotalPages = Math.ceil(filteredPatients.length / patientsPerPage);

        indexOfFirstPatient = Math.min(indexOfFirstPatient, (adjustedTotalPages - 1) * patientsPerPage);
        indexOfLastPatient = indexOfFirstPatient + patientsPerPage;
    }

    const currentPatients = searchInput !== '' ? filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient) : patients.slice(indexOfFirstPatient, indexOfLastPatient);

    const handlePatientClick = (id) => {
        navigate(`/dashboard/patients/${id}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        setCurrentPage(1);
    };

    return (
        <div className={`w-full h-[100vh] pl-[18rem] pr-10 py-24 pt-[7rem] overflow-y-auto transition-all ease-in-out duration-300 ${themeMode === 'dark' ? 'bg-[rgb(40,40,40)]' : 'bg-[rgb(245,245,245)]'} rounded-xl`}>
            <div className='flex justify-between'>
                <h1 className='text-2xl pl-2'>Patientenliste</h1>
                <div className='flex gap-1 text-lg items-center relative'>
                    <FaSearch className=' absolute left-3' />
                    <input
                        type="text"
                        placeholder='Suche'
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className='border-2 border-gray-300 bg-transparent transition-all ease-in-out duration-300 rounded-lg px-2 pl-9 py-1 mr-5'
                    />
                    <Button text='Benachrichtigung senden' onClick={() => setModalOpen(true)} />
                </div>
            </div>
            {
                loading ? <div className={`flex w-full h-full justify-center items-center`}>
                    <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
                </div> :
                    <div>
                        <table className='w-full mt-5 text-lg'>
                            <thead className='border-b-2 border-gray-300'>
                                <tr className='text-left'>
                                    <th className='py-2 pl-5 w-[600px]'>Name</th>
                                    <th className='py-2'>Telefon</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPatients.length === 0 ? <p className='text-start py-3 px-5 text-lg'>Keine Patienten gefunden.</p> :
                                    searchInput !== '' && currentPatients.filter((patient) =>
                                        patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                                        patient.email.toLowerCase().includes(searchInput.toLowerCase())
                                    ).length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="text-center py-5">
                                                Keine übereinstimmenden Patienten gefunden.
                                            </td>
                                        </tr>
                                    ) : (
                                        currentPatients.map((patient) => {
                                            const isMatch =
                                                patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                                                patient.email.toLowerCase().includes(searchInput.toLowerCase());

                                            if (!searchInput || isMatch) {
                                                return (
                                                    <tr
                                                        key={patient._id}
                                                        className={`${themeMode === "dark" ? 'hover:bg-gray-600 text-white' : 'hover:bg-gray-300 text-black'} cursor-pointer transition ease-in-out duration-300 border-b-2 border-gray-300`}
                                                    >
                                                        <td className='py-3 pl-5 flex gap-3 items-center' onClick={() => handlePatientClick(patient._id)}>
                                                            <img src={patient.profilePicture ? patient.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'}
                                                                alt="" className='w-12 object-cover h-12 rounded-full' />
                                                            <div className='flex flex-col'>
                                                                <span>{patient.name}</span>
                                                                <span className='text-gray-400'>{patient.email}</span>
                                                            </div>
                                                        </td>
                                                        <td className='py-3' onClick={() => handlePatientClick(patient._id)}>
                                                            {patient.phone}
                                                        </td>
                                                        <td className="py-3 relative">
                                                            <div className="flex gap-2 items-center justify-center text-3xl ">
                                                                <div className={`px-2 py-1.5 duration-200 rounded-lg ${themeMode === 'dark' ? 'hover:bg-[rgb(120,120,120)]' : 'hover:bg-[rgb(170,170,170)]'}`}
                                                                    onClick={() => setSingleModalOpen({ show: true, patientId: patient._id })}>
                                                                    <MdMessage className='cursor-pointer' />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                            return null;
                                        })
                                    )}
                            </tbody>
                        </table>
                        {
                            totalPages > 1 && (
                                <div className='flex justify-end mt-5 text-lg'>
                                    <p className='mr-5'>Zeige {indexOfFirstPatient + 1} - {Math.min(indexOfLastPatient, totalPatients)} von {totalPatients} Patienten</p>
                                    <button
                                        className={`mx-1 px-3 py-1  ${currentPage === 1 ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                                        onClick={() => handlePageChange(1)}
                                    >
                                        Erste
                                    </button>
                                    {pages.slice(Math.max(0, currentPage - Math.floor(pageRangeDisplayed / 2)),
                                        Math.min(totalPages, currentPage + Math.floor(pageRangeDisplayed / 2))).map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`mx-1 px-3 py-1  ${currentPage === pageNumber ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </button>
                                        ))}
                                    <button
                                        className={`mx-1 px-3 py-1  ${currentPage === totalPages ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                                        onClick={() => handlePageChange(totalPages)}
                                    >
                                        Letzte
                                    </button>
                                </div>
                            )
                        }
                    </div>
            }
            {
                modalOpen && <PushAllForm token={token} setModalOpen={setModalOpen} modalOpen={modalOpen} />
            }
            {
                singleModalOpen.show && <NotificationSingle token={token} setModalOpen={setSingleModalOpen} modalOpen={singleModalOpen} />
            }
        </div>
    )
}