import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Pages/Signin/Login';
import Dashboard from './Components/NavComponents/Dashboard/Dashboard';
import { myContext } from './myContext';
import { Toaster } from 'react-hot-toast';
import Header from './Components/Pages/Static/Header';
import SideNav from './Components/SideNav/SideNav';
import PatientsList from './Components/NavComponents/Patients/PatientsList';
import PatientDetails from './Components/NavComponents/Patients/PatientDetails';
import Chat from './Components/NavComponents/Chat/ChatSkele';
import { VscLoading } from 'react-icons/vsc';
import Profile from './Components/NavComponents/ProfilePage/Profile';
import axios from 'axios';
import NotificationList from './Components/NavComponents/Notifications/NotificationList';

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notificationReceived, setNotificationReceived] = useState(false);
  const [themeMode, setThemeMode] = useState(localStorage.getItem("themeMode") || 'light');
  const [selectedPatient, setSelectedPatient] = useState({
    name: '',
    id: '',
    profilePicture: '',
    email: '',
    phone: '',
  });
  const [me, setMe] = useState({});

  const jwtToken = localStorage.getItem("jwtToken");

  useEffect(() => {
    if (jwtToken) {
      const expirationTime = localStorage.getItem("jwtTokenExpiration");
      const currentTime = new Date().getTime();

      if (expirationTime && currentTime <= expirationTime) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("jwtTokenExpiration");
      }
    } else {
      setLoggedIn(false);
    }

  }, [jwtToken]);

  const getMe = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/auth/me`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      setLoading(false);
      setMe(res.data);
    }
    catch (error) {
      console.error(error);
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("jwtTokenExpiration");
      setLoading(false);
    }
  }

  useEffect(() => {
    getMe();
  }, [jwtToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location.pathname !== '/chat') {
        setSelectedPatient({ name: '', id: '', profilePicture: '', email: '', phone: '' });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);


  const toggleTheme = () => {
    setThemeMode((prevThemeMode) => {
      const newThemeMode = prevThemeMode === 'light' ? 'dark' : 'light';
      localStorage.setItem("themeMode", newThemeMode);
      return newThemeMode;
    });
  };

  if (loading) {
    return <div className={`${themeMode === "light" ? "bg-slate-200 text-black" : "bg-[rgb(20,20,20)] text-white"} flex w-full h-screen justify-center items-center`}>
      <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
    </div>
  }

  return (
    <myContext.Provider value={{ themeMode, toggleTheme, selectedPatient, setSelectedPatient, notificationReceived, setNotificationReceived }}>
      <div className={`transition-all ease-in-out duration-300 ${themeMode === "light" ? "bg-slate-200 text-black" : "bg-[rgb(20,20,20)] text-white"}`}>
        <Router>
          {loggedIn ? (
            <>
              <Toaster />
              <Header token={jwtToken} setLoggedIn={setLoggedIn} />
              <SideNav token={jwtToken} setLoggedIn={setLoggedIn} me={me} />
              <div className=''>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard token={jwtToken} />} />
                  <Route path="/dashboard/patients" element={<PatientsList token={jwtToken} />} />
                  <Route path="/dashboard/patients/:id" element={<PatientDetails token={jwtToken} />} />
                  <Route path="/chat" element={<Chat token={jwtToken} />} />
                  <Route path='/profile' element={<Profile token={jwtToken} getMe={getMe} me={me} setLoggedIn={setLoggedIn} />} />
                  <Route path='/dashboard/notifications' element={<NotificationList token={jwtToken} />} />
                  <Route path="*" element={<Navigate to="/dashboard" replace={true} />} />
                </Routes>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          )}
        </Router>
      </div>
    </myContext.Provider>
  );
}