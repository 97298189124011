import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoKebabHorizontal } from 'react-icons/go';

function PatientListTable({
    patients,
    handleEditPatient,
    handleDeletePatient,
    setAddNewCase,
    searchInput,
    themeMode,
}) {
    const [menuOpen, setMenuOpen] = useState({
        show: false,
        id: null,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const patientsPerPage = 7;
    const totalPatients = patients.length;
    const totalPages = Math.ceil(totalPatients / patientsPerPage);
    const pageRangeDisplayed = 5;
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    let indexOfLastPatient = currentPage * patientsPerPage;
    let indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    let filteredPatients = [];

    if (searchInput !== '') {
     filteredPatients = patients.filter((patient) =>
            patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            patient.email.toLowerCase().includes(searchInput.toLowerCase())
        );
    
        const adjustedTotalPages = Math.ceil(filteredPatients.length / patientsPerPage);
    
        indexOfFirstPatient = Math.min(indexOfFirstPatient, (adjustedTotalPages - 1) * patientsPerPage);
        indexOfLastPatient = Math.min(indexOfFirstPatient + patientsPerPage, filteredPatients.length);
    }
    
    const currentPatients = searchInput !== '' ? filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient) : patients.slice(indexOfFirstPatient, indexOfLastPatient);    

    const handlePatientClick = (id) => {
        navigate(`/dashboard/patients/${id}`);
    };

    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMenuOpen({ show: false, id: null });
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <table className='w-full mt-5 text-lg'>
                <thead className='border-b-2 border-gray-300'>
                    <tr className='text-left'>
                        <th className='py-2 pl-5 w-[600px]'>Name</th>
                        <th className='py-2'>Telefon</th>
                    </tr>
                </thead>
                <tbody>
                    {searchInput !== '' && currentPatients.filter((patient) =>
                        patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                        patient.email.toLowerCase().includes(searchInput.toLowerCase())
                    ).length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center py-5">
                                Keine übereinstimmenden Patienten gefunden.
                            </td>
                        </tr>
                    ) : currentPatients.length === 0 ? <p className='text-start py-3 px-5 text-lg'>Keine Patienten gefunden.</p> : (
                        currentPatients.map((patient) => {
                            const isMatch =
                                patient.name.toLowerCase().includes(searchInput.toLowerCase()) ||
                                patient.email.toLowerCase().includes(searchInput.toLowerCase());

                            if (!searchInput || isMatch) {
                                return (
                                    <tr
                                        key={patient._id}
                                        className={`${themeMode === "dark" ? 'hover:bg-gray-600 text-white' : 'hover:bg-gray-300 text-black'} cursor-pointer transition ease-in-out duration-300 border-b-2 border-gray-300`}
                                    >
                                        <td className='py-3 pl-5 flex gap-3 items-center' onClick={() => handlePatientClick(patient._id)}>
                                            <img src={patient.profilePicture ? patient.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'}
                                                alt="" className='w-12 object-cover h-12 rounded-full' />
                                            <div className='flex flex-col'>
                                                <span>{patient.name}</span>
                                                <span className='text-gray-400'>{patient.email}</span>
                                            </div>
                                        </td>
                                        <td className='py-3' onClick={() => handlePatientClick(patient._id)}>
                                            {patient.phone}
                                        </td>
                                        <td className="py-3 relative">
                                            <div className="flex gap-2 items-center justify-center text-lg">
                                                <div className="relative duration-300" >
                                                    <button
                                                        className={`flex relative flex-col gap-1 justify-center items-center z-40 ${themeMode === 'dark' ? 'text-white' : 'text-black'}`}
                                                        onClick={() => setMenuOpen({ show: !menuOpen.show, id: patient._id })}>
                                                        <GoKebabHorizontal className='text-3xl' />
                                                    </button>
                                                    <div className={`absolute w-[240px] z-50 right-0 backdrop-blur-[10px] 
                                                            rounded-xl shadow-2xl ${menuOpen.show && menuOpen.id === patient._id ? 'block' : 'hidden'}`}
                                                        style={{ backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.1)'}` }} >
                                                        {
                                                            menuOpen.show && menuOpen.id === patient._id && (
                                                                <ul className='flex flex-col gap-3 py-5' ref={menuRef}>
                                                                    <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                                                                        'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                                                                        onClick={() => navigate(`/dashboard/patients/${patient._id}`)}>
                                                                        <p className='text-xl'>Profil anzeigen</p>
                                                                    </li>
                                                                    <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                                                                        'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                                                                        onClick={() => handleEditPatient(patient._id)}>
                                                                        <p className='text-xl'>Profil bearbeiten</p>
                                                                    </li>
                                                                    <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                                                                        'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                                                                        onClick={() => setAddNewCase({ show: true, patientId: patient._id })}>
                                                                        <p className='text-xl'>Neuen Fall hinzufügen</p>
                                                                    </li>
                                                                    <li className={`cursor-pointer duration-300 px-5 py-2 ${themeMode === 'dark' ?
                                                                        'hover:bg-[rgb(100,100,100)]' : 'hover:bg-[rgb(230,230,230)]'}`}
                                                                        onClick={() => handleDeletePatient({ show: true, patient: patient._id })}>
                                                                        <p className='text-xl'>Profil löschen</p>
                                                                    </li>
                                                                </ul>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );

                            }
                            return null;
                        })
                    )}
                </tbody>
            </table>
            {
                totalPages > 1 && (
                    <div className='flex items-center justify-end mt-5 text-lg'>
                        <p className='mr-5'>Zeige {indexOfFirstPatient + 1} - {Math.min(indexOfLastPatient, totalPatients)} von {totalPatients} Patienten</p>
                        <button
                            className={`mx-1 px-3 py-1  ${currentPage === 1 ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            Erste
                        </button>
                        {pages.slice(Math.max(0, currentPage - Math.floor(pageRangeDisplayed / 2)),
                            Math.min(totalPages, currentPage + Math.floor(pageRangeDisplayed / 2))).map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`mx-1 px-3 py-1  ${currentPage === pageNumber ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                                    disabled={currentPage === pageNumber}
                                    onClick={() => handlePageChange(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                        <button
                            className={`mx-1 px-3 py-1  ${currentPage === totalPages ? 'bg-blue-800 text-white' : 'bg-gray-400 text-gray-700'} rounded-xl`}
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            Letzte
                        </button>
                    </div>
                )
            }
        </div>
    )
}

export default PatientListTable;