import React, { useEffect, useState } from 'react'
import { HiOutlineUserGroup } from "react-icons/hi2";
import { myContext } from '../../../myContext';
import axios from 'axios';
import SmallPatientList from './SmallPatientList';
import { VscLoading } from 'react-icons/vsc';

export default function Dashboard({ token }) {
  const { themeMode } = React.useContext(myContext);
  const [patientCount, setPatientCount] = useState(0);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/auth/countsForAdmin`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setPatientCount(res.data.patientsCount);
        setAppointmentCount(res.data.upcomingFollowUpVisits.length);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [])

  return (
    <div className={`w-full h-[100vh] pl-[18rem] pr-10 pt-[6.5rem] overflow-y-auto transition-all ease-in-out duration-300 ${themeMode === 'dark' ? 'bg-[rgb(40,40,40)]' : 'bg-[rgb(245,245,245)]'} rounded-xl`}>
      {
        loading ? <div className={`flex w-full h-full justify-center items-center`}>
          <VscLoading className='animate-spin text-center text-[5rem] text-blue-800' />
        </div> :
          <>
            <div className='flex justify-between pr-10 text-white'
              style={{ textShadow: '0px 0px 2px rgba(0,0,0,0.5)' }}>
              <div className='flex gap-10'>
                <div className='bg-gradient-to-r from-[#275cfa] to-[#05b5fe] opacity-95 w-[300px] h-[130px] flex justify-start pl-7 items-center rounded-lg relative overflow-hidden'>
                  <HiOutlineUserGroup className='text-[6rem] text-white absolute bottom-[-1.5rem] right-0 blur-[1px] opacity-40' />
                  <div className='flex flex-col gap-2'>
                    <p className='text-4xl'>{patientCount}</p>
                    <p className='text-xl'>Gesamtpatienten</p>
                  </div>
                </div>
                <div className='bg-gradient-to-r from-[#b3b300] to-[#e6e600] opacity-95 w-[300px] h-[130px] flex justify-start pl-7 items-center rounded-lg relative overflow-hidden'>
                  <HiOutlineUserGroup className='text-[6rem] text-white absolute bottom-[-1.5rem] right-0 blur-[1px] opacity-40' />
                  <div className='flex flex-col gap-2'>
                    <p className='text-4xl'>{appointmentCount}</p>
                    <p className='text-xl'>Termine heute</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex gap-10 mt-5'>
              <div className='flex flex-col'>
                <SmallPatientList token={token} />
              </div>
            </div>
          </>
      }
    </div>
  )
} 