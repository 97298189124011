import React, { useState, useEffect, useRef, useCallback } from 'react';
import ThemeToggler from '../../Pages/Static/ThemeToggler';
import { myContext } from '../../../myContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import logo from '../../../assets/logo.png';

export default function Header({ token, setLoggedIn }) {
  const [isOpen, setIsOpen] = useState(false);
  const { themeMode } = React.useContext(myContext);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [me, setMe] = useState({
    name: "",
    email: "",
  });
  const date = moment().format('DD MMM,YY hh:mm A');

  useEffect(() => {
    const getMe = async () => {
      try {
        const meResponse = await axios.get(`${process.env.REACT_APP_ENDPOINT}/auth/me`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMe({
          name: meResponse.data.name,
          email: meResponse.data.email
        })
      }
      catch (error) {
        console.error(error);
      }
    }
    getMe();
  }, [token]);

  const handleMouseEnter = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/auth/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('jwtTokenExpiration');
        setLoggedIn(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`fixed w-full z-50 transition-all duration-300 ease-in-out  ${themeMode === "light" ? "bg-slate-200 text-black" : "bg-[rgb(20,20,20)] text-white"}`}>
      <div className='flex justify-between pl-7 px-16 py-3'>
      <img className='w-14 h-14' src={logo} alt='logo' />
        <div className='flex items-center'>
          <div className='flex justify-between items-center'>
            <p className='text-lg font-bold mr-5'>{date}</p>
            <ThemeToggler />
            <div className='relative ml-5'>
              <div
                className='flex gap-2'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  className='w-8 h-8 rounded-full cursor-pointer'
                  src='https://picsum.photos/200'
                  alt='profile'
                />
                <h1 className='ml-2 text-xl font-bold cursor-pointer'>
                  {me.name}
                </h1>
              </div>
              <div
                ref={dropdownRef}
                className={`
                      absolute py-3 right-0 mt-2 px-2 w-[180px] opacity-100 rounded-lg shadow-xl overflow-hidden transition-all duration-300 ease-in-out 
                      transform origin-top border-t border-gray-400 ${isOpen ? 'scale-y-100 opacity-100' : 'scale-y-0 opacity-0'
                  }`}
                style={{
                  backdropFilter: 'blur(10px)',
                  WebkitBackdropFilter: 'blur(10px)',
                  backgroundColor: `${themeMode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.3)'}`
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className='z-30'>
                  <ul className='text-lg'>
                    <li className='px-2 mb-1'>{me.email}</li>
                    <hr className='border-gray-500 my-2 border-dotted' />
                    <li className='p-2 cursor-pointer' onClick={() => navigate('/profile')}>Profil</li>
                    <hr className='border-gray-500 my-2 border-dotted' />
                    <li className='p-2 text-red-500  cursor-pointer' onClick={handleLogout}>Abmelden</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className='border-gray-500 shadow-lg' />
    </div>
  );
}