import React from "react";
import { myContext } from "../../../myContext";
import axios from "axios";
import { toast } from "react-hot-toast";
import Button from "../../Pages/Static/Button";
import { VscLoading } from "react-icons/vsc";

export default function NotificationSingle({ token, setModalOpen, modalOpen }) {
  const { themeMode } = React.useContext(myContext);
  const [message, setMessage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [patient, setPatient] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const getPatient = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/patient/getPatient/${modalOpen.patientId}`,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setPatient(response.data.patient);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getPatient();
  }, [modalOpen.patientId]);

  const sendNotification = async (e) => {
    e.preventDefault();

    if (message === "")
      return toast.error("Bitte geben Sie eine Nachricht ein!");
    const formData = new FormData();
    formData.append("title", title);
    formData.append("message", message);
    formData.append("patientId", modalOpen.patientId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/patient/sendNotificationSingle`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Benachrichtigung erfolgreich gesendet!");
        setModalOpen(false);
      }
    } catch (error) {
      toast.error("Benachrichtigung konnte nicht gesendet werden!");
      console.error(error);
    }
  };

  return (
    <div className="absolute bottom-0 left-0 z-50 w-full h-full flex flex-col items-center justify-center">
      <div
        className=" w-auto max-h-screen overflow-y-auto backdrop-blur-[15px] rounded-xl shadow-2xl "
        style={{
          backgroundColor: `${themeMode === "dark"
              ? "rgba(255,255,255,0.1)"
              : "rgba(255,255,255,0.1)"
            }`,
        }}
      >
        <div className="flex flex-col p-10 text-lg">
          <div className="flex gap-5 justify-center items-center">
            <span className="text-2xl font-bold mb-5">
              Push-Benachrichtigung für
            </span>
            <div className="flex mb-5 items-center bg-blue-800 px-3 py-1 rounded-full w-[210px] h-[60px]">
              {loading === true ? (
                <VscLoading className="animate-spin text-2xl text-white" />
              ) : (
                <img
                  src={
                    patient.profilePicture
                      ? patient.profilePicture
                      : "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
                  }
                  alt="Profil"
                  className="w-[40px] h-[40px] rounded-full"
                />
              )}
              <div className="flex flex-col ml-4">
                {loading === true ? (
                  <VscLoading className="animate-spin text-center ml-10 text-2xl text-white" />
                ) : (
                  <div className="text-white">
                    <p>{patient.name}</p>
                    <p>{patient.phone}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <input className="p-3 rounded-xl bg-transparent border-gray-400 border w-full mb-5"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Titel"
          />
          <textarea
            className="p-3 rounded-xl bg-transparent border-gray-400 border w-full h-80 resize-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Hier Nachricht eingeben..."
          />
          <div className="flex justify-end mt-5 gap-3">
            <Button text="Abbrechen" onClick={() => setModalOpen(false)} />
            <Button text="Senden" onClick={sendNotification} />
          </div>
        </div>
      </div>
    </div>
  );
}
